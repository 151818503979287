<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12" v-if="!mostrar">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="cedula" header="N° Cédula" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.cedula}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="celular" header="N° Celular" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.celular}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 150px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger"  v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 150px">
						<template #body="slotProps">
							<Button icon="pi pi-caret-right" class="p-button-rounded p-button-success mr-2" @click="Ver(slotProps.data)" v-if="edit"/>
						</template>
					</Column>
				</DataTable>
			</div>

			<div class="col-12" v-if="mostrar">
				<Card>
					<template #content>
						<div class="fluid formgrid grid">
							<div class="col-8">
								<h5>Paciente:  {{(product.nombre+' '+product.apellido)}}</h5>
							</div>
                            <div class="col-4">
								<h5>Cédula: {{product.cedula}}</h5>
							</div>
							<div class="col-8">
								<h5>Edad:  {{(product.edad)}} años</h5>
							</div>
                            <div class="col-4">
								<h5>Celular: {{product.celular}}</h5>
							</div>
							<div class="col-8">
								<h5>Dirección:  {{(product.direccion)}}</h5>
							</div>
						</div>
					</template>
					<template #footer>	
						<Button label="Agregar Historia" icon="pi pi-plus" class="p-button-primary mr-2 mb-2"  @click="Nuevo(1)" :loading="$store.state.loading" :disabled="($store.state.pago.deuda > $store.state.pago.monto)"/>
						<Button label="Agregar Indicaciones" icon="pi pi-plus" class="p-button-secondary mr-2 mb-2"  @click="Nuevo(2)" :loading="$store.state.loading" :disabled="($store.state.pago.deuda > $store.state.pago.monto)"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger mr-2 mb-2" @click="mostrar = false"/>
					</template>
				</Card>	
			</div>
			<Divider v-if="mostrar"/>
			<div class="col-7" v-if="mostrar">
				<div class="card">
					<div class="grid">
						<div class="col-12" v-for="(item, id) in product.historial" :key="id">
							<Panel :header="'Historial: '+item.fechad+' '+item.especialidad_nom" :toggleable="true" :collapsed="true">
								<template #icons>
									<button class="p-panel-header-icon p-link mr-2" @click="Editar(1,item)">
										<span class="pi pi-pencil"></span>
									</button>
									<button class="p-panel-header-icon p-link mr-2" @click="VerPDF(1,item)">
										<span class="pi pi-print"></span>
									</button>
									<Menu id="config_menu" ref="menu" :model="items" :popup="true" />
								</template>
								<p><strong>Antecedentes:</strong> {{item.antecedente}}</p>
								<p><strong>Motivo de Consulta:</strong> {{item.motivo}}</p>
								<p><strong>Examen Físico:</strong> {{item.examenfisico}}</p>
								<p><strong>Impresión Diagnóstica:</strong> {{item.diagnostico}}</p>
								<p><strong>Plan:</strong> {{item.tratamiento}}</p>
							</Panel>
						</div>
					</div>
				</div>
			</div>

			<div class="col-5" v-if="mostrar">
				<div class="card">
					<div class="grid">
						<div class="col-12" v-for="(item, id) in product.indicaciones" :key="id">
							<Panel :header="'Indicaciones: '+item.fechad+' '+item.especialidad_nom" :toggleable="true" :collapsed="true">
								<template #icons>
									<button class="p-panel-header-icon p-link mr-2" @click="Editar(2,item)">
										<span class="pi pi-pencil"></span>
									</button>
									<button class="p-panel-header-icon p-link mr-2" @click="VerPDF(2,item)">
										<span class="pi pi-print"></span>
									</button>
									<Menu id="config_menu" ref="menu" :model="items" :popup="true" />
								</template>
								<p><strong>Medicamentos:</strong> {{item.medicamento}}</p>
								<p><strong>Indicaciones:</strong> {{item.tratamiento}}</p>
							</Panel>
						</div>
					</div>
				</div>
			</div>

		</div>


		<Dialog v-model:visible="dialogo" :style="{width: size}" :header="product.titulo" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
					<div class="col-3">
						<label for="name">Fecha</label>
						<InputText id="fecha"  v-model="product.fecha" type="date"/>
						<small class="p-invalid" v-if="!product.fecha">Es requirido</small>
					</div>
					<div class="col-6">
						<label for="name">Especialidad</label>
						<Dropdown v-model="product.especialidad" :options="especialidades" optionLabel="nombre" placeholder="Selecciona..." @change="Hora"></Dropdown>
						<small class="p-invalid" v-if="!product.especialista">Es requirido</small>
					</div>
					<div class="col-12" v-if="product.caso==1">
						<label for="name">Antecedentes</label>
						<Textarea v-model="product.antecedente"  rows="3"/>						
						<small class="p-invalid" v-if="!product.antecedente">Es requirido</small>
					</div>
					<div class="col-12" v-if="product.caso==1">
						<label for="name">Motivo de Consulta</label>
						<InputText id="name" v-model="product.motivo" required="true"/>
						<small class="p-invalid" v-if="!product.motivo">Es requirido</small>
					</div>
					<div class="col-12" v-if="product.caso==1">
						<label for="name">Examen Físico</label>
						<Textarea v-model="product.examenfisico"  rows="5"/>						
						<small class="p-invalid" v-if="!product.examenfisico">Es requirido</small>
					</div>
					<div class="col-12" v-if="product.caso==2">
						<label for="name">Medicamentos</label>
						<Textarea v-model="product.medicamento"  rows="5"/>						
						<small class="p-invalid" v-if="!product.medicamento">Es requirido</small>
					</div>
					<div class="col-12">
						<label for="name">Impresión Diagnóstica</label>
						<Textarea v-model="product.diagnostico"  rows="5"/>						
						<small class="p-invalid" v-if="!product.diagnostico">Es requirido</small>
					</div>
					<div class="col-12">
						<label for="name">Plan</label>
						<Textarea v-model="product.tratamiento"  rows="5"/>						
						<small class="p-invalid" v-if="!product.tratamiento">Es requirido</small>
					</div>
				</div>
				<br>
				<div v-if="product.especialidad">
					<div class="grid">
						<div class="col-2">
							Rehabilitación <Checkbox v-model="product.fisio" :binary="true" v-if="product.especialidad.id==9"/> 
						</div>
						<div class="col-2"  v-if="product.fisio">
							N° Sesiones: <InputNumber id="sec" v-model="product.fisioterapia.secciones" integeronly min="0"/>
						</div>
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.chc" :binary="true"/> CHC
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.crio" :binary="true"/> CRIO.
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ir" :binary="true"/> IR
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.paraf" :binary="true"/> PARAF.
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.tens" :binary="true"/> TENS
						</div>
						<div class="col-12">
							<Textarea v-model="product.fisioterapia.indic"  rows="3"/>						
						</div>					
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.us" :binary="true"/> US
						</div>
						<div class="col-10">
							<Textarea v-model="product.fisioterapia.us_indic"  rows="2"/>						
						</div>					
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.masot" :binary="true"/> Masoterapia
						</div>
						<div class="col-10">
							<Textarea v-model="product.fisioterapia.masot_indic"  rows="2"/>						
						</div>					
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-12">
							<label for="name">Estiramientos</label>
							<Textarea v-model="product.fisioterapia.estiram"  rows="2"/>						
						</div>
						<div class="col-12">
							<label for="name">Movilizaciones</label>
							<Textarea v-model="product.fisioterapia.moviliz"  rows="2"/>						
						</div>
						<div class="col-12">
							<label for="name">Electroterapia</label>
							<Textarea v-model="product.fisioterapia.electrot"  rows="2"/>						
						</div>
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-12">
							Ejercicios
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_pasivo" :binary="true"/> Pasivos
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_asist" :binary="true"/> Activ. Asist.
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_libre" :binary="true"/> Activ. Libres
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_resist" :binary="true"/> Resistidos
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_isome" :binary="true"/> Isometric.
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_isoto" :binary="true"/> Isotonicos
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.ejerc_mecanot" :binary="true"/> Mecanoterapia
						</div>
						<div class="col-10">
							<Textarea v-model="product.fisioterapia.ejerc_mecanot_indic"  rows="2"/>						
						</div>					
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.klapp" :binary="true"/> Klapp
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.leob" :binary="true"/> Leo B.
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.william" :binary="true"/> Williams
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.goldw" :binary="true"/> Goldwhite
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.coordin" :binary="true"/> Coordinación
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.equilib" :binary="true"/> Equilibrio
						</div>
						<div class="col-3">
							<Checkbox v-model="product.fisioterapia.mckez" :binary="true"/> Mc Kenzie
						</div>
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-12">
							Reeducar Marcha
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.reeduc_mul" :binary="true"/> Muletas
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.reeduc_bast" :binary="true"/> Bastón
						</div>
						<div class="col-2">
							<Checkbox v-model="product.fisioterapia.reeduc_andad" :binary="true"/> Andadera
						</div>
						<div class="col-6">
							<label for="name">Descarga</label>
							<Textarea v-model="product.reeduc_descarga"  rows="2"/>
						</div>
					</div>
					<div class="grid fluid mt-3" v-if="product.fisio">
						<div class="col-4">
							<Checkbox v-model="product.fisioterapia.ejerc_propioc" :binary="true"/> Ejerc. Propiocepción
						</div>
						<div class="col-4">
							<Checkbox v-model="product.fisioterapia.ejerc_respirat" :binary="true"/> Ejerc. Respiratorios
						</div>
						<div class="col-12">
							<label for="name">Otro</label>
							<Textarea v-model="product.fisioterapia.otro"  rows="2"/>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="dialogo = false" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Salud','MisPacientes');
	import PDF from "../service/PDF";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				mostrar: false,
				dialogo: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				especialidades: [],
				especialidad: null,
				fisio: false,
				fisioterapia: {
					id: 0,
					secciones: 0,
					chc: false,
					crio: false,
					ir: false,
					paraf: false,
					tens: false,
					indic: null,
					us: false,
					us_indic: null,
					masot: false,
					masot_indic: null,
					estiram: null,
					moviliz: null,
					electrot: null,
					ejerc_pasivo: false,
					ejerc_asist: false,
					ejerc_libre: false,
					ejerc_resist: false,
					ejerc_isome: false,
					ejerc_isoto: false,
					ejerc_mecanot: false,
					ejerc_mecanot_indic: null,
					klapp: false,
					leob: false,
					william: false,
					goldw: false,
					coordin: false,
					equilib: false,
					mckez: false,
					reeduc_mul: false,
					reeduc_bast: false,
					reeduc_andad: false,
					reeduc_descarga: null,
					ejerc_propioc: false,
					ejerc_respirat: false,
					otro: null,
				}
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result.pacientes;
					this.especialidades = response.result.especialidades;
					this.$store.commit('Loading');
				}); 
			},
			Nuevo(index) {
				this.nuevo = true;
				if(index==1){this.product.titulo = 'Historial';}
				if(index==2){this.product.titulo = 'Indicaciones';}
                this.product.caso = index;
				if(this.especialidades.length == 1){
					this.product.especialidad = this.especialidades[0];
				} 
				this.product.motivo = null;
				this.product.antecedente = null;
				this.product.examenfisico = null;
				this.product.fisio = false;
				this.product.fisioterapia = {...this.fisioterapia};
				this.dialogo = true;
			},
			Editar(index,datos) {
				this.nuevo = false;
				if(index==1){
					this.product.titulo = 'Historial';
					this.product.motivo = datos.motivo;
					this.product.antecedente = datos.antecedente;
					this.product.examenfisico = datos.examenfisico;
				}
				if(index==2){
					this.product.titulo = 'Indicaciones';
					this.product.medicamento = datos.medicamento;
				}
                this.product.code = datos.id;
                this.product.caso = index;
				this.product.fecha = datos.fecha;
				this.product.diagnostico = datos.diagnostico;
				this.product.tratamiento = datos.tratamiento;

				if(datos.fisioterapia){
					this.product.fisio = true;
					this.product.fisioterapia = datos.fisioterapia;
				} else {
					this.product.fisio = false;
					this.product.fisioterapia = {...this.fisioterapia};
				}
				if(this.especialidades.length == 1){
					this.product.especialidad = this.especialidades[0];
				}
				this.dialogo = true;
			},
			VerPDF(index,datos) {
				var informacion = {};
				const doc = new PDF();
				informacion.paciente = this.product.nombre+' '+this.product.apellido;
				informacion.cedula = this.product.cedula;
				informacion.edad = this.product.edad;
				informacion.especialista = datos.espec;
				informacion.fecha = datos.fechad;
				informacion.diagnostico = datos.diagnostico;
				informacion.tratamiento = datos.tratamiento;
				if(index==1){
					informacion.titulo = 'Historial';
					informacion.motivo = datos.motivo;
					informacion.antecedente = datos.antecedente;
					informacion.examenfisico = datos.examenfisico;
					doc.Historial(informacion);
				}
				if(index==2){
					informacion.titulo = 'Indicaciones';
					informacion.medicamento = datos.medicamento;
					doc.Indicaciones(informacion);
				}
			},
			Ver(product) {
				this.nuevo = false;
				this.product = {...product};
				if(this.especialidades.length == 1){
					this.product.especialidad = this.especialidades[0];
				}
				Consulta.Procesar('Buscar',{
						product: product,
					}).then(response => {
						//this.$store.state.error = response.result;
						this.$store.commit('Loading');
						if (response.result) {
							this.product.historial = response.result.historial;
							this.product.indicaciones = response.result.indicaciones;
							this.mostrar = true;
						}
					});
			},			
			Guardar() {
				if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha', life: 5000});
				} else if (this.product.motivo == null && this.product.caso == 1) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Motivo', life: 5000});
				} else if (this.product.antecedente == null && this.product.caso == 1) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Antecedente', life: 5000});
				} else if (this.product.examenfisico == null && this.product.caso == 1) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Examen Físico', life: 5000});
				} else if (this.product.diagnostico == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Diagóstico', life: 5000});
				} else if (this.product.tratamiento == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tratamiento', life: 5000});
				} else if (this.product.medicamento == null && this.product.caso == 2) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Medicamentos', life: 5000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Ver(this.product);
							this.dialogo = false;
						}
					});
				}
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
                    //this.$store.state.error = response;
					this.$toast.add(Consulta.Mens(response.result));
					this.$store.commit('Loading');
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
